import request from '@/utils/request'

const oss_tokenApi = {
  oss_token_list: '/oss_token/'
}

/**
 * 获取授权车辆列表
 */
export function oss_token_list () {
  return request({
    url: oss_tokenApi.oss_token_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
